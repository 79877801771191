import React from "react";
import ReactDOM from "react-dom";
import { isMobile } from 'react-device-detect';
import Matter from "matter-js";

import zeroImage from './0.png';
import oneImage from './1.png';
import twoImage from './2.png';
import threeImage from './3.png';
import fourImage from './4.png';
import fiveImage from './5.png';
import sixImage from './6.png';
import sevenImage from './7.png';

import zeroMeme from './meme/0.png';
import oneMeme from './meme/1.png';
import twoMeme from './meme/2.png';
import threeMeme from './meme/3.png';
import fourMeme from './meme/4.png';
import fiveMeme from './meme/5.png';
import sixMeme from './meme/6.png';

import soundEffect from './pop.mp3';

const images = [oneImage, twoImage, threeImage, fourImage, fiveImage, sixImage, sevenImage]
const memes = [zeroMeme, oneMeme, twoMeme, threeMeme, fourMeme, fiveMeme, sixMeme]
const stackOfMemes = memes.reverse();

class Baloons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleResize(){
    window.location.reload(false)
  }

    componentDidMount() {

      window.addEventListener("resize", this.handleResize);

      const audio = new Audio(soundEffect);

      const baloonsToRender = 40;
      let poppedBallons = 0;
      let memeOnTheScene = 0;

      const baloons = [];
      const memes = [];

        /* eslint-disable */

        // Matter.js - http://brm.io/matter-js/

// // Setup the sounds to be used.
// let sound1 = new Howl({
//   src: ['audio/pop.webm', 'audio/pop.mp3']
// });


let rect1;
let rect2;
let rect3;
let rect4;

const container = this.refs.scene;


let mConstraint;

let world;


let theWindow;
let theExtra;
let theScale;
let thePush;

let smallBig = 1;

let elementToChange = this.refs.scene;

let mobile = isMobile;

const genBaloon = (fallFromTheTop = false) => {
  const randX = theWindow*10 + Math.random() * (window.innerWidth-theWindow*10);
  const randY = !fallFromTheTop ? theWindow*10 + Math.random() * (window.innerHeight-theWindow*10) : -20;
  //const randRadius = Math.random() * 40 + 10;

  const randomNmr = Math.floor((Math.random() * 7));
        if(randomNmr === 3 || randomNmr === 4 || randomNmr === 6){
            smallBig = 0.68;
        }else{
            smallBig = 0.9;
        }

    const randRadius = 120 * theScale * theWindow * smallBig;
    const randImg = images[randomNmr];
  // const randAirFriction = Math.random() * 1;
  const circle = Bodies.circle(randX, randY, randRadius, {
    render: {
      //fillStyle: '#55ff55'
       sprite: {
           texture: randImg,
           xScale: 0.6*theScale*theWindow,
           yScale: 0.6*theScale*theWindow
       },
       type: 'baloon',
    },
    density: 0.005*(theExtra-(theWindow/10)),
    frictionAir: (0.3+(randomNmr/300))*(theExtra-(theWindow/10)),
    restitution: .3*(theExtra-(theWindow/10)),
    friction: .01*(theExtra-(theWindow/10))
  });
  World.add(engine.world, circle);
  baloons.push(circle);
}
const genMeme = (meme) => {
  const squareSize = isMobile ? 80 : 120;
  const randX = (window.innerWidth / 2 - squareSize) + squareSize / 2 * Math.random();
  const square = Bodies.rectangle(randX, -10, squareSize, squareSize, {
    render: {
      fillStyle: '#55ff55',
      restitution: 0.6,
      friction: 0.1,
      type: 'meme',
      sprite: {
        texture: meme,
        xScale: !isMobile ? 0.6*theScale*theWindow : 0.45 * theWindow,
        yScale: !isMobile ? 0.6*theScale*theWindow : 0.45 * theWindow,
    },
    },
  })
  World.add(engine.world, square);
}
const handleMemeStack = () => {
  return stackOfMemes.pop();
}

if (mobile === true){
        //mobile
        theScale = 1.1/1.7;
        theWindow = 0.8/1.7;
        theExtra = 0.6*1.4;
        thePush = 10*0.3;
    }else{
        //desktop
        theScale = 0.12;
        theWindow = (window.innerWidth + window.innerHeight)/350;
        theExtra = 1;
        thePush = 0.7;
    }

    let Engine = Matter.Engine,
        Render = Matter.Render,
        Runner = Matter.Runner,
        Composite = Matter.Composite,
        Composites = Matter.Composites,
        Common = Matter.Common,
        Query = Matter.Query,
        MouseConstraint = Matter.MouseConstraint,
        Mouse = Matter.Mouse,
        World = Matter.World,
        Body = Matter.Body,
        Events = Matter.Events,
        Bodies = Matter.Bodies;

    // create engine
    let engine = Engine.create();

        world = engine.world;

    // create renderer
    let render = Render.create({
        element: this.refs.scene,
        engine: engine,
        options: {
            // width: document.getElementById('container').offsetWidth - 64,
            // height: window.innerHeight,
            width: window.innerWidth,
            height: window.innerHeight,
            background: '#fafafa',
            showAngleIndicator: false,
            wireframes: false,
        }
    });

    Render.run(render);

init();

function init() {




    // create runner
    let runner = Runner.create();
    Runner.run(runner, engine);

    // add bodies
    let offset = 5,
        options = {
            isStatic: true,
            render: {
              fillStyle: 'transparent'
            }
        };

        // add mouse control
    let mouse = Mouse.create(render.canvas);

    mConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.2,
                render: {
                    visible: false
                }
            }

        });

    World.add(world, mConstraint);

    // keep the mouse in sync with rendering
    render.mouse = mouse;

    world.bodies = [];

    // these static walls will not be rendered in this sprites example, see options
    rect1 = Bodies.rectangle(window.innerWidth/2, -100, window.innerWidth, 2, options);

    rect2 = Bodies.rectangle(window.innerWidth/2, window.innerHeight -offset, window.innerWidth * 2, 2, options);

    rect3 = Bodies.rectangle(window.innerWidth - offset, window.innerHeight/2, 2, window.innerHeight * 2, options);

    rect4 = Bodies.rectangle(offset, window.innerHeight/2, 2, window.innerHeight * 2, options);

    World.add(world, rect1);
    World.add(world, rect2);
    World.add(world, rect3);
    World.add(world, rect4);


    for (let i = 0; i < 1; i++) {

      const randX = theWindow*10 + Math.random() * (window.innerWidth-theWindow*10);
      const randY = theWindow*10 + Math.random() * (window.innerHeight-theWindow*10);
      //const randRadius = Math.random() * 40 + 10;

      const randRadius = 120 * theScale*theWindow*smallBig;
        // const randAirFriction = Math.random() * 1;
        const circle = Bodies.circle(randX, randY, randRadius, {
          render: {
            //fillStyle: '#55ff55'
            sprite: {
                texture: zeroImage,
                xScale: 0.6*theScale*theWindow,
                yScale: 0.6*theScale*theWindow
                },
              id: 'smiley',
              type: 'baloon',
          },
          density: 0.005*(theExtra-(theWindow/10)),
          frictionAir: 0.3*(theExtra-(theWindow/10)),
          restitution: .3*(theExtra-(theWindow/10)),
          friction: .01*(theExtra-(theWindow/10))
        });
        World.add(engine.world, circle);
        baloons.push(circle);

    }


    for (let i = 0; i < baloonsToRender; i++) {
      genBaloon();
    }


    // fit the render viewport to the scene
    Render.lookAt(render, {
        min: { x: 0, y: 0 },
        max: { x: window.innerWidth, y: window.innerHeight }
    });

    // window.onresize = function(event) {
    //     location.reload();

    // }

    // context for MatterTools.Demo
    return {
        engine: engine,
        runner: runner,
        render: render,
        canvas: render.canvas,
        stop: function() {
            Matter.Render.stop(render);
            Matter.Runner.stop(runner);
        }
    };
};

Events.on(mConstraint, "mousedown", clicked)
Events.on(mConstraint, "mouseup", clickedup)

function clickedup(){
    elementToChange.style.cursor = "grab";
}

function clicked() {
        if (mConstraint.body){
            elementToChange.style.cursor = "grabbing";


            // if(mConstraint.body.render.id === 'smiley'){
            //     audio.play();
            //     Matter.Composite.remove(world, mConstraint.body);

          if(mConstraint.body.render.type === 'baloon'){
            if(audio) {
              audio.play();
            }
            Matter.Composite.remove(world, mConstraint.body);
            poppedBallons = poppedBallons + 1;
            rePopulate();


            for (let stack0 of baloons) {
              Body.applyForce(stack0, { x: stack0.position.x, y: stack0.position.y }, {
                x: (Math.random() * (theWindow*2*thePush)) - (theWindow*thePush),
                y: (Math.random() * ((theWindow/2)*thePush)) * - (theWindow*thePush) });

            }

            }
        }
}

const rePopulate = () => {
  console.log(poppedBallons);
  if(poppedBallons < 5) {
    return;
  }
  console.log('be-here', stackOfMemes.length);
  if(Math.random() > 0.66 && stackOfMemes.length) {
    console.log('here', stackOfMemes.length);
    const meme = handleMemeStack();
    genMeme(meme);
  } else {
    genBaloon(true);
  }

}

Events.on(render, 'afterRender', function() {


    let mouse = mConstraint.mouse,
        context = render.context,
        bodies = Composite.allBodies(engine.world),
        startPoint = { x: mouse.position.x-1, y: mouse.position.y-1 },
        endPoint = mouse.position;

    let collisions = Query.ray(bodies, startPoint, endPoint);

    Render.startViewTransform(render);

    if (collisions.length > 0) {
        if (elementToChange.style.cursor != "grabbing"){
            elementToChange.style.cursor = "grab";
        }
    } else {
        elementToChange.style.cursor = "default";
    }

    for (let i = 0; i < collisions.length; i++) {
        let collision = collisions[i];

    }


    Render.endViewTransform(render);

});





    }


  render() {
    return <div ref="scene" />;
  }
}
export default Baloons;
