import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"

import {
  Container,
} from "../components/ui"

import Baloons from "../ballons/ballons"


const Fallback = (props) => {
  console.warn(`No component found for: ${props.blocktype}`)
  return false
}

export default function Homepage(props) {
  const { homepage } = props.data;



  return (
      <Layout {...homepage} headerIsTransparent>
      <Container id="container" style={{
        position: 'fixed',
        top: 0,
        left: '-32px',
        bottom: 0,
        zIndex: '-1',
      }}>
        <Baloons>
        </Baloons>
      </Container>
      </Layout>
  )
}

export const query = graphql`
  {
    homepage {
      id
      title
      description
    }
  }
`
